import { ContentSource } from 'constants/tracking/content-sources'

export function getPromotedClosetContentSource(contentSource?: ContentSource) {
  switch (contentSource) {
    case ContentSource.Search:
      return ContentSource.SearchPromotedClosets
    case ContentSource.Catalog:
      return ContentSource.CatalogPromotedClosets
    default:
      return ContentSource.PromotedClosets
  }
}
