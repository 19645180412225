'use client'

import useAbTest from 'hooks/useAbTest'
import useFeatureSwitch from 'hooks/useFeatureSwitch'

import Banner from './Banner'
import DismissibleStickyBanner from './DismissibleStickyBanner'

type Props = {
  suffix?: JSX.Element
  prefix?: JSX.Element
}

const ShippingFeesAppliedBanner = (props: Props) => {
  const shippingFeesInfoBannerV1 = useAbTest({
    abTestName: 'info_banner_shipping_fees_apply_feed',
  })
  const isStickyShippingInfoBannerOn = useFeatureSwitch('shipping_info_banner_v2_toggle')

  if (isStickyShippingInfoBannerOn) return <DismissibleStickyBanner {...props} />
  if (shippingFeesInfoBannerV1) return <Banner {...props} />

  return null
}

export default ShippingFeesAppliedBanner
