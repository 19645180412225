export enum SeoContentType {
  catalogSeo = 'catalog-seos',
  brandSeo = 'brand-seos',
}

export const COMPANY_NAME = 'Vinted'

export enum ItemAvailabilityVariant {
  BackOrder = 'BackOrder',
  Discontinued = 'Discontinued',
  InStock = 'InStock',
  InStoreOnly = 'InStoreOnly',
  LimitedAvailability = 'LimitedAvailability',
  OnlineOnly = 'OnlineOnly',
  OutOfStock = 'OutOfStock',
  PreOrder = 'PreOrder',
  PreSale = 'PreSale',
  SoldOut = 'SoldOut',
}

export enum OfferItemConditionVariant {
  DamagedCondition = 'DamagedCondition',
  NewCondition = 'NewCondition',
  RefurbishedCondition = 'RefurbishedCondition',
  UsedCondition = 'UsedCondition',
}

export const itemConditionSchemaMarkupMapping = {
  1: OfferItemConditionVariant.NewCondition,
  2: OfferItemConditionVariant.UsedCondition,
  3: OfferItemConditionVariant.UsedCondition,
  4: OfferItemConditionVariant.UsedCondition,
  6: OfferItemConditionVariant.NewCondition,
}

export enum RobotsTxtField {
  UserAgent = 'User-Agent',
  Disallow = 'Disallow',
  Allow = 'Allow',
  Sitemap = 'Sitemap',
}
