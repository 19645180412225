import type {
  Brand,
  BreadcrumbList,
  ListItem,
  Offer,
  Organization,
  Product,
  WebPage,
  Thing,
} from 'schema-dts'

import { isCurrencyAmountDto } from 'libs/utils/currency-amount'
import { ItemDto } from 'types/dtos'
import { CatalogModel } from 'types/models'

import {
  WebPageSchemaProps,
  OrganizationSchemaProps,
  OfferSchemaProps,
  ProductProps,
  TransformListItemSchemaProps,
  PageSchemaProps,
  BrandCatalogPageSchemaProps,
} from '../types/schema-markup'

import {
  COMPANY_NAME,
  ItemAvailabilityVariant,
  itemConditionSchemaMarkupMapping,
} from '../constants'

export const transformWebPageSchema = ({
  name,
  description,
  url,
}: WebPageSchemaProps): WebPage => ({
  '@type': 'WebPage',
  name,
  description,
  url,
})

export const transformOrganizationSchema = ({
  name,
  url,
  logo,
}: OrganizationSchemaProps): Organization => ({
  '@type': 'Organization',
  name,
  url,
  logo,
})

export const transformListItemSchema = (
  { title, url }: TransformListItemSchemaProps,
  position: number,
): ListItem => ({
  '@type': 'ListItem',
  position,
  item: {
    '@id': url,
    name: title,
  },
})

export const transformBreadCrumblistSchema = (
  itemList: Array<TransformListItemSchemaProps>,
): BreadcrumbList => ({
  '@type': 'BreadcrumbList',
  itemListElement: itemList.map((item, index) => transformListItemSchema(item, index + 1)),
})

export const transformOfferSchema = ({
  url,
  priceCurrency,
  price,
  availability,
  itemCondition,
}: OfferSchemaProps): Offer => ({
  '@type': 'Offer',
  url,
  priceCurrency,
  price,
  availability,
  itemCondition,
})

export const transformProductSchema = ({ name, description, image }: ProductProps): Product => ({
  '@type': 'Product',
  name,
  description,
  image,
})

export const transformBrandSchema = (name: string): Brand => ({
  '@type': 'Brand',
  name,
})

export const transformHomepageSchemaMarkup = ({
  meta_title,
  meta_description,
  baseUrl,
  logo,
}: PageSchemaProps): Thing => ({
  ...transformWebPageSchema({
    name: meta_title,
    description: meta_description,
    url: baseUrl,
  }),
  breadcrumb: transformBreadCrumblistSchema([{ title: 'Home', url: baseUrl }]),
  mainEntity: transformOrganizationSchema({
    name: COMPANY_NAME,
    url: baseUrl,
    logo,
  }),
})

export const transformProductPageSchemaMarkup = (item: ItemDto): Thing => {
  const offerPrice = isCurrencyAmountDto(item.offer_price)
    ? item.offer_price.amount
    : item.offer_price

  return {
    ...transformProductSchema({
      name: item.title,
      description: item.description,
      image: item.photos.length ? item.photos[0]!.url : '',
    }),
    brand: transformBrandSchema(item.brand_dto?.title || ''),
    offers: transformOfferSchema({
      url: item.url,
      priceCurrency: item.price.currency_code,
      price: offerPrice ?? item.price.amount,
      availability: ItemAvailabilityVariant.InStock,
      itemCondition: itemConditionSchemaMarkupMapping[item.status_id],
    }),
  }
}

export const transformBreadcrumbModelsFromCatalogModel = (
  breadcrumbs: Array<CatalogModel>,
  baseUrl: string,
) => breadcrumbs.map(({ title, url }) => ({ title, url: `${baseUrl}${url}` }))

export const transformBrandCatalogPageSchemaMarkup = ({
  meta_title,
  meta_description,
  baseUrl,
  breadcrumbs,
}: BrandCatalogPageSchemaProps): Thing => ({
  ...transformWebPageSchema({
    name: meta_title,
    description: meta_description,
    url: baseUrl,
  }),
  breadcrumb: transformBreadCrumblistSchema(breadcrumbs),
})
