import { uniqBy } from 'lodash'

import Advertisement from 'components/Advertisement'

import { FeedEventDto, ItemDto } from 'types/dtos'
import {
  ClosetModel,
  GenericPromoBoxModel,
  IvsGuidelineBannerModel,
  ListerActivationBannerModel,
  PromotionalListingBannerInCatalogFeedModel,
} from 'types/models'
import { GridItem } from 'types/components'

export const getNextSequenceItem = <InsertItem extends Record<string, unknown>>(
  index: number,
  itemIndex: number,
  sequence: Array<string>,
  items: Record<string, Array<GridItem<InsertItem>>>,
  generateItem?: GenerateGridItem<InsertItem>,
): GridItem<InsertItem> | void => {
  const currentIndex = itemIndex % sequence.length
  const targetType = sequence[currentIndex]!
  const target = items[targetType]

  if (!target?.length)
    return generateItem?.({
      index,
      itemIndex,
      type: targetType,
    })

  return target.shift() as GridItem<InsertItem>
}

export const getInsertableItems = <InsertItem extends Record<string, unknown>>(
  positions: InsertPositions,
  forceTruePosition: boolean | undefined,
  baseItemCount: number,
  sequence: Array<string>,
  items: Record<string, Array<GridItem<InsertItem>>>,
  order: number,
  generateItem?: GenerateGridItem<InsertItem>,
) => {
  const insertableItems: Array<InsertableItem<InsertItem>> = []

  for (
    let index = positions.first, itemIndex = 0;
    index < baseItemCount;
    index += positions.distance, itemIndex += 1
  ) {
    const item = getNextSequenceItem(index, itemIndex, sequence, items, generateItem)

    if (!item) {
      // eslint-disable-next-line no-continue
      continue
    }

    insertableItems.push({
      item,
      position: index,
      order,
      forceTruePosition,
    })
  }

  return insertableItems
}

export type InsertPositions = {
  first: number
  distance: number
}

export interface InsertableItem<InsertItem extends Record<string, unknown>> {
  forceTruePosition?: boolean
  item: GridItem<InsertItem>
  position: number
  order: number
}
export type GenerateGridItem<T> = (params: {
  index: number
  itemIndex: number
  type: string
}) => GridItem<T> | void

export interface GridItemInsert<
  InsertItem extends Record<string, unknown>,
  SequenceKeys extends string,
  InsertMap = Record<SequenceKeys, Array<GridItem<InsertItem>>>,
> {
  items: InsertMap
  positions: InsertPositions
  sequence?: Array<SequenceKeys>
  generateItem?: GenerateGridItem<InsertItem>
  forceTruePosition?: boolean
}

/**
 * Accepts an array of `gridItemInsert`.
 * Generates a list of index and items to insert based on `positions`.
 * Mutates the list of provided `gridItemInsert.items`.
 * Multiple `gridItemInsert` elements means multiple passes.
 */
export const buildGridItems = <
  BaseItem extends Record<string, unknown>,
  InsertItem extends Record<string, unknown>,
  SequenceKeys extends string,
  InsertMap extends Record<SequenceKeys, Array<GridItem<InsertItem>>>,
>(
  baseItems: Array<GridItem<BaseItem>>,
  gridItemInserts: Array<GridItemInsert<InsertItem, SequenceKeys, InsertMap>>,
) => {
  const gridItems: Array<GridItem<BaseItem | InsertItem>> = [...baseItems]

  const sortedItemInserts = gridItemInserts
    .reduce(
      (
        acc: Array<InsertableItem<InsertItem>>,
        { items, sequence = Object.keys(items), positions, generateItem, forceTruePosition },
        index,
      ) => {
        const insertableItems = getInsertableItems<InsertItem>(
          positions,
          forceTruePosition,
          baseItems.length,
          sequence,
          items,
          index,
          generateItem,
        )

        return [...acc, ...insertableItems]
      },
      [],
    )
    .sort(
      (current, previous) => current.position - previous.position || current.order - previous.order,
    )

  sortedItemInserts.forEach(({ position, item }, index) => {
    const previousForceTruePositionGridItemCount = sortedItemInserts
      .slice(0, index)
      .filter(({ forceTruePosition }) => forceTruePosition).length
    const finalPosition = position + index - previousForceTruePositionGridItemCount

    gridItems.splice(finalPosition, 0, item)
  })

  return gridItems
}

export const feedEventToItem = (item: FeedEventDto): GridItem<FeedEventDto> => ({
  type: item.entity_type,
  id: `${item.entity_type}-${item.entity.id}`,
  data: item,
})

export const closetToItem = (closet: ClosetModel): GridItem<ClosetModel> => ({
  type: 'closet_promotion',
  id: `closet-${closet.user.id}`,
  data: closet,
})

export const adIndexToItem = (
  index: number,
  props: Partial<ComponentProps<typeof Advertisement>> = {},
): GridItem<Partial<ComponentProps<typeof Advertisement>>> => ({
  type: 'ad',
  id: `ad-${index}`,
  data: props,
})

export const itemDtoToItem = <T extends { id: number } = ItemDto>(item: T): GridItem<T> => ({
  type: 'item',
  id: `item-${item.id}`,
  data: item,
})

export const listerActivationBannerToItem = (
  index: number,
  banner: ListerActivationBannerModel,
): GridItem<ListerActivationBannerModel> => ({
  type: 'lister_activation_banner',
  id: `lister-activation-banner-${index}`,
  data: banner,
})

export const promotionalListingBannerToItem = (
  index: number,
  banner: PromotionalListingBannerInCatalogFeedModel,
): GridItem<PromotionalListingBannerInCatalogFeedModel> => ({
  type: 'promotional_listing_banner',
  id: `promotional-listing-banner-${index}`,
  data: banner,
})

export const ivsGuidelineBannerToItem = (
  index: number,
  banner: IvsGuidelineBannerModel | undefined,
): GridItem<IvsGuidelineBannerModel | Record<string, unknown>> => ({
  type: 'ivs_guideline_banner',
  id: `ivs-guideline-banner-${index}`,
  data: banner || {},
})

export const genericPromoBoxToItem = (
  promoBox: GenericPromoBoxModel,
): GridItem<GenericPromoBoxModel> => ({
  type: 'generic_promo_box',
  id: `generic-promo-box-${promoBox.id}`,
  data: promoBox,
})

export const generateGenericPromoBoxItem = (boxes, { index }) => {
  const uniquePromoBoxes: Array<GenericPromoBoxModel> = uniqBy(boxes, ({ position }) => position)

  const matchingPromoBox = uniquePromoBoxes.find(
    promoBox => promoBox.position === index && !promoBox.isControl,
  )

  if (!matchingPromoBox) return undefined

  return genericPromoBoxToItem(matchingPromoBox)
}
