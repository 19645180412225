'use client'

import { MouseEvent } from 'react'
import { Cell } from '@vinted/web-ui'
import classnames from 'classnames'

import { ClosetPromotionItemDto } from 'types/dtos'
import { ClosetUserModel } from 'types/models'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'
import { ClosetPromotionEngagementEventTarget } from 'constants/closet-promotion'
import {
  clickClosetPromotionEvent,
  clickEvent,
  clickListItemEvent,
  favouriteItemEvent,
} from 'libs/common/event-tracker/events'
import { getPromotedClosetContentSource } from 'libs/utils/contentSource'
import { navigateToPage } from 'libs/utils/window'
import { mapOriginToWindow } from 'libs/utils/url'

import useTracking from 'hooks/useTracking'

import { ControlScrollType } from 'components/HorizontalScrollArea/HorizontalScrollArea'
import HorizontalScrollArea from 'components/HorizontalScrollArea'

import ClosetUserInfo from './ClosetUserInfo'
import ClosetItem from './ClosetItem'
import ClosetCTA from './ClosetCTA'

type Props = {
  user: ClosetUserModel
  items?: Array<ClosetPromotionItemDto>
  wide?: boolean
  position: number
  contentSource?: ContentSource
  homepageSessionId?: string
}

const MIN_PHOTO_COLLAGE_COUNT = 3

const Closet = ({
  user,
  items = [],
  wide = false,
  position,
  contentSource,
  homepageSessionId,
}: Props) => {
  const { track } = useTracking()

  const isCollageEnabled = !!items[0]?.photos && items[0].photos.length >= MIN_PHOTO_COLLAGE_COUNT

  function trackEngagementEvent(target: string, shouldTrackEngagement = true) {
    if (shouldTrackEngagement) {
      track(
        clickClosetPromotionEvent({
          ownerId: user.id,
          contentSource: getPromotedClosetContentSource(contentSource),
          target,
        }),
      )
    }
  }

  const handleItemClick = (item: ClosetPromotionItemDto, index: number) => (event: MouseEvent) => {
    event.preventDefault()

    const trackingEvent = clickListItemEvent({
      id: item.id,
      position: index + 1,
      contentType: ListItemContentType.Item,
      contentSource: ContentSource.PromotedClosets,
      homepageSessionId,
    })

    track(trackingEvent)
    trackEngagementEvent(ClosetPromotionEngagementEventTarget.Item)

    navigateToPage(mapOriginToWindow(item.url))
  }

  function handleCtaClick() {
    const event = clickEvent({
      target: ClickableElement.SeeWholeClosetCta,
      targetDetails: `${user.id}`,
    })

    track(event)
    trackEngagementEvent(ClosetPromotionEngagementEventTarget.AllItems)
  }

  function handleUserInfoClick() {
    trackEngagementEvent(ClosetPromotionEngagementEventTarget.UserProfile)
  }

  function handleViewProfileClick() {
    trackEngagementEvent(ClosetPromotionEngagementEventTarget.ViewProfile)
  }

  function handleItemFavouriteToggle({
    itemId,
    isFollowEvent,
  }: {
    itemId: number
    isFollowEvent: boolean
  }) {
    track(
      favouriteItemEvent({
        itemId,
        isFollowEvent,
        homepageSessionId,
        contentSource: getPromotedClosetContentSource(contentSource),
      }),
    )
    track(clickEvent({ target: ClickableElement.Favourite }))

    trackEngagementEvent(ClosetPromotionEngagementEventTarget.ItemFavourite, isFollowEvent)
  }

  const closetClass = classnames('closet', 'closet--with-horizontal-scroll', {
    'closet--wide': wide,
  })
  const closetItemClass = classnames('closet__item', { 'closet__item--collage': isCollageEnabled })

  return (
    <div className="closet-container">
      <Cell styling={Cell.Styling.Tight}>
        <div className="closet-container__item-horizontal-scroll">
          <div className={closetClass}>
            <ClosetUserInfo
              user={user}
              position={position}
              onClick={handleUserInfoClick}
              onViewProfileClick={handleViewProfileClick}
              isBusinessUser={!!items[0]!.business_user}
            />

            <HorizontalScrollArea controlsScrollType={ControlScrollType.Partial}>
              {items.map((item, index) => (
                <HorizontalScrollArea.Item
                  className={closetItemClass}
                  key={`closet-item-${item.id}`}
                >
                  <ClosetItem
                    item={item}
                    onClick={handleItemClick(item, index)}
                    onItemFavouriteToggle={handleItemFavouriteToggle}
                    showPhotoCollage={isCollageEnabled && index === 0}
                    index={index}
                    homepageSessionId={homepageSessionId}
                  />
                </HorizontalScrollArea.Item>
              ))}

              <HorizontalScrollArea.Item className={closetItemClass}>
                <ClosetCTA
                  itemCount={user.itemCount}
                  userId={user.id}
                  onClick={handleCtaClick}
                  position={items.length + 1}
                />
              </HorizontalScrollArea.Item>
            </HorizontalScrollArea>
          </div>
        </div>
      </Cell>
    </div>
  )
}

export default Closet
