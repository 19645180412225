'use client'

import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'
import { noop } from 'lodash'

import { UiState } from 'constants/ui'
import { getBanners } from 'data/api'
import { transformBanners } from 'data/transformers/banners'
import { BannersModel } from 'types/models'
import * as apiTypes from 'types/api'
import useCookie from 'libs/common/cookie-manager/hooks/useCookie'
import { cookiesDataByName } from 'libs/common/cookie-manager/cookies-data'

type Banners = {
  fetchBanners: () => void
  banners: BannersModel
  uiState: UiState
}

type BannersProviderProps = {
  children: ReactNode
}

export const initialValues: Banners = {
  fetchBanners: noop,
  banners: transformBanners({}),
  uiState: UiState.Idle,
}

export const BannersContext = createContext<Banners>(initialValues)

const BannersProvider = ({ children }: BannersProviderProps) => {
  const [uiState, setUiState] = useState(UiState.Idle)
  const [banners, setBanners] = useState<BannersModel>(transformBanners({}))
  const cookies = useCookie()

  const fetchBanners = useCallback(
    async ({ disableCache = false }: apiTypes.GetBannersArgs = {}) => {
      if ([UiState.Success, UiState.Pending].includes(uiState)) return

      setUiState(UiState.Pending)
      cookies.set(cookiesDataByName.banners_ui_state, UiState.Pending)

      const response = await getBanners({ disableCache })

      if ('errors' in response) {
        setUiState(UiState.Failure)
        cookies.set(cookiesDataByName.banners_ui_state, UiState.Failure)

        return
      }

      const transformedBanners = transformBanners(response.banners)

      setBanners(transformedBanners)
      setUiState(UiState.Success)
      cookies.set(cookiesDataByName.banners_ui_state, UiState.Success)
    },
    [uiState, cookies],
  )

  const value = useMemo(
    () => ({
      fetchBanners,
      banners,
      uiState,
    }),
    [fetchBanners, banners, uiState],
  )

  return <BannersContext.Provider value={value}>{children}</BannersContext.Provider>
}

export default BannersProvider
